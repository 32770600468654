import { apiDelete, apiGet, apiPatch, apiPost } from "./_server";
import { IUpdatePasswordRequest, IUpdateProfileRequest, IUser } from "./models/user";
import { ICreateAddressRequest, IUpdateAddressRequest, IUserAddress } from "./models/user_address";

export async function getProfile(): Promise<IUser> {
  return apiGet({ path: "me" });
}

export async function updateProfile(payload: IUpdateProfileRequest): Promise<IUser> {
  return apiPatch({ path: "me", data: payload });
}

export async function deleteAccount(password: string): Promise<void> {
  return apiDelete({ path: "me", data: { password } });
}

export async function updatePassword(payload: IUpdatePasswordRequest): Promise<void> {
  return apiPatch({ path: "me/password", data: payload });
}

export async function requestEmailConfirmationCode(): Promise<void> {
  return apiPost({ path: "me/request_email_confirmation_code" });
}

export async function validateEmailConfirmationCode(emailConfirmationCode: string): Promise<void> {
  return apiPost({
    path: "me/validate_email_confirmation_code",
    data: { emailConfirmationCode },
  });
}

export async function requestAccountDeleteCode(): Promise<void> {
  return apiPost({ path: "me/request_account_delete_confirmation_code" });
}

export async function validateAccountDeleteConfirmationCode(
  accountDeleteConfirmationCode: string
): Promise<void> {
  return apiPost({
    path: "me/validate_account_delete_confirmation_code",
    data: { accountDeleteConfirmationCode },
  });
}

export async function registerAddress(payload: ICreateAddressRequest): Promise<IUserAddress> {
  return apiPost({ path: "app/user_address", data: payload });
}

export async function getAddresses(): Promise<IUserAddress[]> {
  const { data } = await apiGet({ path: "app/user_address" });

  return data || [];
}

export async function updateAddress(id: string, payload: IUpdateAddressRequest): Promise<void> {
  return apiPatch({ path: `app/user_address/${id}`, data: payload });
}

export async function deleteAddress(id: string): Promise<void> {
  return apiDelete({ path: `app/user_address/${id}` });
}
