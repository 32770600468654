import SweetScroll from "sweet-scroll";

import { useUserStore } from "../store/user";

import { toast } from "./toast";

export function copyToClipboard(value: string, successText = "Copiado") {
  navigator.clipboard.writeText(value);
  toast("success", successText);
}

export function resetBlockersAndShowGlobalScroll() {
  useUserStore().windowScrollBlockers = new Set();
  showGlobalScroll();
}

export function hideGlobalScroll() {
  document.body.style.overflowY = "hidden";
}

export function showGlobalScroll() {
  document.body.style.overflowY = "auto";
}

export function hideOrShowGlobalScroll(id: string, isOpen: boolean) {
  const userStore = useUserStore();
  if (isOpen) {
    userStore.windowScrollBlockers.add(id);
  } else {
    userStore.windowScrollBlockers.delete(id);
  }

  if (userStore.windowScrollBlockers.size) {
    hideGlobalScroll();
  } else {
    showGlobalScroll();
  }
}

export function scrollToCenter(
  element: HTMLElement,
  scrollableContainer: HTMLElement | string | (Window & typeof globalThis) = window
) {
  scrollableContainer =
    typeof scrollableContainer === "string"
      ? document.querySelector<HTMLElement>(scrollableContainer)!
      : scrollableContainer;
  if (!scrollableContainer) {
    throw new Error("Scrollable Container não encontrado");
  }

  const scroller = new SweetScroll({ duration: 500 }, scrollableContainer);

  // Obter a posição do elemento
  const elementRect = element.getBoundingClientRect();
  const currentScroll: number =
    (<any>scrollableContainer).scrollTop ?? (<any>scrollableContainer).scrollY;
  const elementVerticalPosition = elementRect.top + currentScroll;

  // Calcular a posição para centralizar o elemento
  const containerHeight =
    (<any>scrollableContainer).clientHeight ?? (<any>scrollableContainer).innerHeight;
  const offset = containerHeight / 2 - elementRect.height / 2;
  // const offset = 0;
  const scrollPosition = elementVerticalPosition - offset;

  // Scroll para a posição calculada
  scroller.to(scrollPosition);
}

export function scrollToFirstInvalidInputIfExists(options?: {
  scrollableContainer?: HTMLElement | string;
}) {
  const firstInputError = [...$(".input-error")].find((element) => element.innerText);
  if (firstInputError) {
    const firstInvalidInput = firstInputError.parentElement!;
    scrollToCenter(firstInvalidInput, options?.scrollableContainer); // Scroll para o primeiro input inválido
  }
}
