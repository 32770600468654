/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/vue";

import { translationPerProperty } from "./property_translation";
import { toast } from "./toast";

/**
 * Erro que pode ser lançado para interromper o fluxo de execução e exibir um toast de erro ao usuário.
 *
 * @class
 * @extends Error
 */
export class AppError extends Error {
  constructor(message: string, public readonly info?: any) {
    super(message);
  }
}

export function handleAppError(error: unknown): void {
  Sentry.captureException(error);

  if (error instanceof AppError || error instanceof APIError) {
    toast("error", error.message);
  } else {
    throw error;
  }
}

export class APIError extends Error {
  constructor(public response: { message: string; id?: string }, public statusCode: number) {
    super(response.message);
  }
}

export class FormError extends Error {
  constructor(message: string, public properties: IPropertyError[]) {
    super(message);
  }
}

export interface IPropertyError {
  property: keyof typeof translationPerProperty;
  errorMessages: string[];
}

export function isAPIError(error: any): error is APIError {
  return error instanceof APIError;
}

export function isFormError(error: any): error is FormError {
  return error instanceof FormError;
}

export const errorMessagePerKey = {
  DEFAULT: "Houve um erro inesperado. Contate o suporte para mais informações.",
  PRODUCT_VARIANT_ALREADY_EXISTS_ON_CART: "Essa opção já existe no seu carrinho.",
};
