import { apiGet } from "./_server";
import {
  IMultipleProductShipmentDto,
  IShipmentOptionResponseDto,
  IMultipleProductsShipmentResponseDto,
  ISingleProductShipmentDto,
} from "./models/shipment";

export async function getShipmentByProductSlug(slug: string, payload: ISingleProductShipmentDto) {
  return apiGet<IShipmentOptionResponseDto>({
    path: `shipment/product/${slug}`,
    config: { params: { json: JSON.stringify(payload) } },
  });
}

export async function getMultipleShipments(payload: IMultipleProductShipmentDto) {
  return apiGet<IMultipleProductsShipmentResponseDto>({
    path: "shipment",
    config: { params: { json: JSON.stringify(payload) } },
  });
}
