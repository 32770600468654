import { defineProduct, defineReview, Offer, useSchemaOrg } from "@unhead/schema-org";
import { useSeoMeta } from "@unhead/vue";

import { IProduct } from "../api/models/product";
import { IReview } from "../api/models/review/review";

import { getAllUniqueImagesFromProduct, toLocalDate } from ".";

export function updateMetaTags(tags: { title?: string; description?: string }) {
  useSeoMeta(
    {
      // Deve ser extraído pelo crawler mesmo que atualizado dinamicamente
      title: tags.title,
      description: tags.description,

      // Por serem metas de preview não devem funcionar quando atualizados dinamicamente
      ogTitle: tags.title,
      ogDescription: tags.description,
      twitterTitle: tags.title,
      twitterDescription: tags.description,
    },
    {
      mode: "client",
      tagDuplicateStrategy: "replace",
    }
  );
}

export function updateMetaTitle(title: string) {
  useSeoMeta(
    {
      // Deve ser extraído pelo crawler mesmo que atualizado dinamicamente
      title,

      // Por serem metas de preview não devem funcionar quando atualizados dinamicamente
      ogTitle: title,
      twitterTitle: title,
    },
    {
      mode: "client",
      tagDuplicateStrategy: "replace",
    }
  );
}

export function updateMetaDescription(description: string) {
  useSeoMeta(
    {
      // Deve ser extraído pelo crawler mesmo que atualizado dinamicamente
      description,

      // Por serem metas de preview não devem funcionar quando atualizados dinamicamente
      ogDescription: description,
      twitterDescription: description,
    },
    {
      mode: "client",
      tagDuplicateStrategy: "replace",
    }
  );
}

export function updateProductSchemaMarkup(product: IProduct, slicedDescription: string) {
  const schemaMarkupProduct: Parameters<typeof defineProduct>[0] = {
    "@id": product.id,
    name: product.name,
    description: slicedDescription,
    image: getAllUniqueImagesFromProduct(product)[0]?.url,
    sku: product.id,
    brand: {
      name: product.brand.name,
      url: `${window.location.origin}/marca/${product.brand.slug}`,
    },
    offers: [
      {
        availability: "InStock",
        price: (product.offerPrice ?? product.price)! / 100,
        priceValidUntil: product.offerValidUntil || undefined,
        priceCurrency: "BRL",
      },
    ],
  };

  if (product.averageRating) {
    schemaMarkupProduct.aggregateRating = {
      ratingValue: product.averageRating,
      ratingCount: product.ratings,
      bestRating: 5,
      worstRating: 1,
    };
  }

  if (product.offerPrice && Array.isArray(schemaMarkupProduct.offers)) {
    const currentOffer = <Offer>schemaMarkupProduct.offers[0];
    currentOffer.priceSpecification = [
      {
        "@type": "UnitPriceSpecification",
        price: product.offerPrice / 100,
        priceCurrency: "BRL",
        priceType: "https://schema.org/SalePrice", // Preço com desconto
      },
      {
        "@type": "UnitPriceSpecification",
        price: product.price! / 100,
        priceCurrency: "BRL",
        priceType: "https://schema.org/ListPrice", // Preço original
      },
    ];
  }

  useSchemaOrg([defineProduct(schemaMarkupProduct)]);
}

export function updateProductReviewsSchemaMarkup(productId: string, reviews: IReview[]) {
  useSchemaOrg(
    reviews.map((review) =>
      defineReview({
        "@id": review.id,
        itemReviewed: {
          "@id": `${window.location.origin}/#/schema/product/${productId}`,
        },
        author: { name: review.userName },
        reviewRating: {
          ratingValue: review.rating,
          bestRating: 5,
          worstRating: 1,
        },
        datePublished: review.hasTimeZone
          ? new Date(review.commentedAt)
          : toLocalDate(review.commentedAt),
        name: review.title || undefined,
        reviewBody: review.comment || undefined,
        inLanguage: "pt-BR",
      })
    )
  );
}
