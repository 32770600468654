import { defineStore } from "pinia";

import { clearMyCart } from "../api/cart_product";
import { ICard } from "../api/models/card";
import { ICartProduct } from "../api/models/cart_product";
import { ICoupon } from "../api/models/coupon";
import { ICreateOrderResponseDto, PaymentMethodEnum } from "../api/models/order";
import { IMultipleProductsShipmentResponseDto } from "../api/models/shipment";
import { IUserAddress } from "../api/models/user_address";
import { getMultipleShipments } from "../api/shipment";
import { useParallelRequestsNormalizer } from "../composables/useParallelRequestsNormalizer";
import router from "../router";
import { getQueryFromIndividualItem, isDefinedAndNotNull, syncOrderItems, toast } from "../utils";

import { IProduct } from "./../api/models/product";
import { useUserStore } from "./user";

export const useCartStore = defineStore({
  id: "cart",
  state: () => {
    return {
      isMenuOpen: false,
      isUpdatingItems: false,
      isRestartingCheckout: false,
      focusOnPaymentSection: false,
      items: null as ICartProduct[] | null,
      // Item sendo comprado diretamente da página do produto sem estar adicionado no carrinho
      individualItem: null as ICartProduct | null,
      selectedCheckoutAddress: null as IUserAddress | null,
      selectedPaymentMethod: null as PaymentMethodEnum | null,
      selectedCheckoutCard: null as ICard | null,
      shipment: null as IMultipleProductsShipmentResponseDto | null,
      orderResult: null as ICreateOrderResponseDto | null,
      isAddingNewCard: false,
      isProcessingPayment: false,
      coupon: null as ICoupon | null,
    };
  },
  getters: {
    checkoutItems(state): ICartProduct[] | null {
      const isIndividualCheckout = this.isIndividualCheckout;

      if (isIndividualCheckout) {
        return state.individualItem ? [state.individualItem] : null;
      }

      return state.items;
    },
    rawSubtotal() {
      const checkoutItems = this.checkoutItems as ICartProduct[] | null;

      const itemsSubtotal =
        checkoutItems?.reduce(
          (total, curr) => total + curr.quantity * (curr.product.offerPrice ?? curr.product.price)!,
          0
        ) ?? 0;

      return itemsSubtotal;
    },
    subtotal() {
      const coupon = this.coupon as ICoupon | null;
      const rawSubtotal: number = this.rawSubtotal;

      return coupon
        ? rawSubtotal - Math.round(rawSubtotal * (coupon.discountPercentage / 100)) //
        : rawSubtotal;
    },
    rawTotal(state) {
      const rawSubtotal: number = this.rawSubtotal;
      const wholeShipmentValue = state.shipment
        ? (1 + state.shipment.discount) * state.shipment.value
        : 0;

      return rawSubtotal + wholeShipmentValue;
    },
    total(state) {
      const subtotal = this.subtotal as number;

      return state.shipment ? subtotal + state.shipment.value : null;
    },
    checkoutAddress(state) {
      return state.selectedCheckoutAddress ?? useUserStore().activeUser?.mainAddress;
    },
    checkoutCard(state) {
      return state.selectedCheckoutCard ?? useUserStore().activeUser?.mainCard;
    },
    isIndividualCheckout() {
      return !!router.currentRoute.value.query["individual-item-slug"];
    },
    isUpdatingShipment(): boolean {
      return normalizedStoreMultipleShipmentsAndFixQuantities.isProcessing.value;
    },
  },
  actions: {
    async restartCheckout(
      items: Parameters<typeof syncOrderItems>[0],
      routeMethod: "replace" | "push"
    ) {
      this.isRestartingCheckout = true;

      if (items.length) {
        await clearMyCart();
        await syncOrderItems(items);

        if (routeMethod === "push") {
          await router.push("/checkout");
        } else if (routeMethod === "replace") {
          await router.replace("/checkout");
        }
      }

      this.isRestartingCheckout = false;
    },
    checkoutIndividualItem(
      product: IProduct,
      quantity: number,
      variantId?: string,
      options?: { routerPush?: boolean }
    ) {
      const localId = `${product.id}-${variantId}`;

      const newIndividualItem: ICartProduct = {
        id: localId,
        product,
        quantity,
        variantId,
      };
      this.individualItem = newIndividualItem;

      if (options?.routerPush) {
        router.push({
          path: "/checkout",
          query: getQueryFromIndividualItem({ slug: product.slug, quantity, variantId }),
        });
      }
    },
    async storeMultipleShipmentsAndFixQuantities(postalCode: string) {
      const hasAnyWithValidQuantity = this.checkoutItems!.some(({ quantity }) => quantity > 0);
      if (!hasAnyWithValidQuantity) {
        return;
      }

      await normalizedStoreMultipleShipmentsAndFixQuantities.request(postalCode);
    },
  },
});

const normalizedStoreMultipleShipmentsAndFixQuantities = useParallelRequestsNormalizer(
  async (localPostalCode: string) => {
    const cartStore = useCartStore();

    return getMultipleShipments({
      postalCode: localPostalCode,
      products: cartStore.checkoutItems!.map((cartItem) => ({
        quantity: cartItem.quantity,
        slug: cartItem.product.slug,
        variantId: cartItem.variantId ?? undefined,
      })),
    });
  },
  {
    onLastResult: (shipment) => {
      const cartStore = useCartStore();

      let didUploadAnyItemQuantity = false;
      shipment.productMetaGroups.forEach(({ products: productMetas }) => {
        productMetas.forEach(({ id, variantId, sentQuantity, fixedQuantity }) => {
          const foundCartItem = cartStore.checkoutItems!.find(
            (cartItem) => cartItem.product.id === id
          );
          if (!foundCartItem) {
            return;
          }
          const foundChosenVariant = foundCartItem.product.variants?.find(
            (variant) => variant.id === variantId
          );
          // Corrige quantidade caso necessário
          if (isDefinedAndNotNull(fixedQuantity) && sentQuantity > fixedQuantity) {
            didUploadAnyItemQuantity = true;
            foundCartItem.didFixQuantity = true;
            foundCartItem.quantity = fixedQuantity;
            if (foundChosenVariant) {
              foundChosenVariant.quantityInStock = fixedQuantity;
            } else {
              foundCartItem.product.quantityInStock = fixedQuantity;
            }
          }
        });
      });
      if (didUploadAnyItemQuantity) {
        toast("info", "Um ou mais produtos tiveram suas quantidades atualizadas.", 6);
      }
      cartStore.shipment = shipment;
    },
  }
);
