import axios from "../axios";

const extensionsSet = new Set([
  "3dv",
  "ai",
  "amf",
  "art",
  "ase",
  "awg",
  "blp",
  "bmp",
  "bw",
  "cd5",
  "cdr",
  "cgm",
  "cit",
  "cmx",
  "cpt",
  "cr2",
  "cur",
  "cut",
  "dds",
  "dib",
  "djvu",
  "dxf",
  "e2d",
  "ecw",
  "egt",
  "emf",
  "eps",
  "exif",
  "fs",
  "gbr",
  "gif",
  "gpl",
  "grf",
  "hdp",
  "heic",
  "heif",
  "icns",
  "ico",
  "iff",
  "int",
  "inta",
  "jfif",
  "jng",
  "jp2",
  "jpeg",
  "jpg",
  "jps",
  "jxr",
  "lbm",
  "liff",
  "max",
  "miff",
  "mng",
  "msp",
  "nef",
  "nitf",
  "nrrd",
  "odg",
  "ota",
  "pam",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pct",
  "pcx",
  "pdd",
  "pdn",
  "pgf",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "png",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "ras",
  "raw",
  "rgb",
  "rgba",
  "rle",
  "sct",
  "sgi",
  "sid",
  "stl",
  "sun",
  "svg",
  "sxd",
  "tga",
  "tif",
  "tiff",
  "v2d",
  "vnd",
  "vrml",
  "vtf",
  "wdp",
  "webp",
  "wmf",
  "x3d",
  "xar",
  "xbm",
  "xcf",
  "xpm",
]);

export function getFileNameFromUrl(url: string) {
  const fileExtension = url.match(/[^\\/]+?(?=\.\w+$)/gi);

  return fileExtension ? fileExtension[0] : "";
}

export function getFileExtensionFromFileName(fileName: string) {
  const fileExtension = fileName.match(/\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gi);

  return fileExtension ? fileExtension[0] : "";
}

export async function downloadFileFromUrl(url: string, fileName?: string) {
  fileName = (fileName ?? getFileNameFromUrl(url))
    .normalize("NFD")
    .replace(/[^-A-Za-z1-9\s]/g, "")
    .replaceAll(" ", "_");

  const fileExtension = getFileExtensionFromFileName(url);

  const { data } = await axios({
    url,
    method: "GET",
    responseType: "blob",
  });

  const fileURL = window.URL.createObjectURL(new Blob([data]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", `${fileName}${fileExtension}`);
  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
}

export function getAsset(path: string) {
  return `${window.location.origin}/assets${path}`;
}

export function isImage(path: string) {
  const ext = path.split(".").pop()!;

  return ext ? extensionsSet.has(ext) : false;
}

export function formatVideoDuration(durationInSeconds: number) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  const parts = [];
  if (hours > 0) parts.push(hours);
  parts.push(minutes.toString().padStart(1, "0"));
  parts.push(seconds.toString().padStart(2, "0"));

  return parts.join(":");
}

export async function stopVideoOnFrame(video: HTMLVideoElement) {
  const isPlaying =
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > video.HAVE_CURRENT_DATA;

  video.muted = true;
  if (!isPlaying) {
    await video.play();
  }

  video.currentTime = 20;
  video.pause();
}
