import "material-symbols";
import VueBarcode from "@chenfengyuan/vue-barcode";
import { createHead, setHeadInjectionHandler } from "@unhead/vue";
import NProgress from "nprogress";
import { createPinia } from "pinia";
import "v-calendar/dist/style.css";
import { createApp } from "vue";
import toast from "vue-toastification";

import "vue-toastification/dist/index.css";
import "waypoints/lib/noframework.waypoints.min.js";
import "nouislider/dist/nouislider.min.css";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import "nprogress/nprogress.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";

import "https://cdnjs.cloudflare.com/ajax/libs/card/2.5.4/card.min.js";

import("jquery").then((jQuery) => {
  window.jQuery = jQuery.default;
  window.$ = jQuery.default;
});

import App from "./App.vue";
import worker from "./mocks";
import { initializeSentry } from "./plugins/sentry";
import router from "./router";
import { handleAppError } from "./utils";

import "./styles/global.postcss";

if (import.meta.env.VITE_ENVIRONMENT?.toString().toLowerCase() === "dev") {
  worker.start({ onUnhandledRequest: "bypass" });
}

const head = createHead();

const app = createApp(App) //
  .use(router)
  .use(createPinia())
  .use(toast)
  .component(VueBarcode.name!, VueBarcode);

setHeadInjectionHandler(() => head);

app.config.errorHandler = handleAppError;

NProgress.configure({ showSpinner: false, minimum: 0.35 });

initializeSentry(app, router);

app.mount("#app");
