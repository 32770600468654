/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/vue";
import { AxiosRequestConfig, Method } from "axios";

import axios from "../axios";
import { useAuthStore } from "../store/auth";
import {
  convertDateStringToISOInObject,
  convertISOToDateInObject,
  convertISOToDateStringInObject,
  toast,
  APIError,
  AppError,
  FormError,
  IPropertyError,
} from "../utils";
import { translationPerProperty } from "../utils/property_translation";

interface IApiCallRequest {
  method: Method;
  path: string;
  data?: any;
  config?: AxiosRequestConfig;
  token?: string;
}

function handleRequestError(requestError: any): void {
  try {
    const { response } = requestError;

    if (!response) {
      throw new AppError("Verifique sua conexão com a internet e tente novamente.");
    }

    if (response.status === 401) {
      useAuthStore().clearSession();

      const message = "Sua sessão expirou. Faça o login novamente.";

      toast("error", message);
      throw new APIError({ message }, response.status);
    } else {
      if (response.data.properties) {
        toast("error", buildFormErrorMessagesHtml(response), 6);
        throw new FormError(response.data.message, response.data.properties);
      }
      throw new APIError({ id: response.data.id, message: response.data.message }, response.status);
    }
  } catch (handledError) {
    Sentry.captureException(handledError);

    throw handledError;
  }
}

async function apiCall({ method, path, data, config, token }: IApiCallRequest): Promise<any> {
  const accessToken = token ?? useAuthStore().accessToken;

  const defaultHeaders = { "X-Timezone": new Date().getTimezoneOffset() };

  const requestConfig: AxiosRequestConfig = {
    method,
    url: path,
    data,
    headers: accessToken
      ? { Authorization: `Bearer ${accessToken}`, ...defaultHeaders }
      : defaultHeaders,
    ...config,
  };

  try {
    convertDateStringToISOInObject(requestConfig.data, ["birthdate"]);
    const response = await axios(requestConfig);

    if (response.data) {
      convertISOToDateStringInObject(response.data, ["birthdate"]);
      convertISOToDateInObject(response.data, ["createdDate", "updatedDate"]);

      return response.data;
    }

    return undefined;
  } catch (error) {
    handleRequestError(error);
  }
}

export async function apiGet<T = any>(
  payload: Omit<IApiCallRequest, "method" | "data">
): Promise<T> {
  return apiCall({ method: "GET", ...payload });
}

export async function apiPost<T = any>(payload: Omit<IApiCallRequest, "method">): Promise<T> {
  return apiCall({ method: "POST", ...payload });
}

export async function apiPatch<T = any>(payload: Omit<IApiCallRequest, "method">): Promise<T> {
  return apiCall({ method: "PATCH", ...payload });
}

export async function apiDelete<T = any>(payload: Omit<IApiCallRequest, "method">): Promise<T> {
  return apiCall({ method: "DELETE", ...payload });
}

function buildFormErrorMessagesHtml(response: any) {
  const formErrorTitle = `<div class="text-lg mb-1">${response.data.message}</div>`;
  const formErrorPropertyMessages = (<IPropertyError[]>response.data.properties)
    .map(({ property, errorMessages }) => {
      const translated = translationPerProperty[property];
      if (!translated) {
        console.warn(`Tradução de propriedade não encontrada: '${property}'`);

        return errorMessages[0];
      }

      return `<strong>${translated}</strong>: ${errorMessages[0]}`;
    })
    .filter((text) => !!text)
    .join("\n");

  return `${formErrorTitle}${formErrorPropertyMessages}`;
}
