import { v4 as uuidV4 } from "uuid";
import { computed, reactive } from "vue";

/**
 * Previne que múltiplas chamadas ao endpoint retorne resultados desordenados. O `useLastResultFromParallelRequests` sempre executará a callback `onLastResult` no resultado da última chamada do request.
 *
 * Use case: Um request X é executado 2 vezes seguidas. É possível que a primeira chamada retorne por último mesmo que a intenção é usar o resultado da segunda chamada (a última).
 *
 * @returns Nova função
 */
export function useParallelRequestsNormalizer<
  RequestFunction extends (...params: any) => Promise<any>
>(
  request: RequestFunction,
  {
    onLastResult,
    onLastResultError,
    onLastResultFinally,
    onCancelPrevious,
  }: {
    onLastResult?: (result: Awaited<ReturnType<RequestFunction>>) => any;
    onLastResultError?: (error: any) => any;
    onLastResultFinally?: () => any;
    onCancelPrevious?: () => any;
  }
) {
  const activeRequestPerId = reactive<Record<string, boolean>>({});
  const isProcessing = computed(() =>
    Object.values(activeRequestPerId).some((isActive) => isActive)
  );

  const isActive = (id: string) => activeRequestPerId[id];
  const activate = (id: string) => (activeRequestPerId[id] = true);
  const deactivate = (id: string) => (activeRequestPerId[id] = false);
  const finish = (id: string) => delete activeRequestPerId[id];

  return {
    isProcessing,
    request: async (...params: Parameters<RequestFunction>) => {
      const requestId = uuidV4();
      try {
        const activeRequestEntries = Object.entries(activeRequestPerId) //
          .filter((entry) => entry[1]);

        if (activeRequestEntries.length) {
          // Significa que há requests inacabados, e ainda sim um novo (esse atual) foi chamado
          activeRequestEntries.forEach(([id]) => {
            // Desativa todos os requests passados, para terem suas respostas ignoradas
            deactivate(id);
            onCancelPrevious?.();
          });
        }

        activate(requestId);

        const result = await request(...(<any>params));

        if (!isActive(requestId)) {
          // TODO Cancelar o request com signal do axios
          return;
        }

        onLastResult?.(result);
      } catch (error) {
        if (isActive(requestId)) {
          onLastResultError?.(error);
        }
      } finally {
        if (isActive(requestId)) {
          onLastResultFinally?.();
        }

        finish(requestId);
      }
    },
  };
}
