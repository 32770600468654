import store from "store2";
import { nextTick } from "vue";
import { LocationQuery } from "vue-router";

import { addToCart, getMyCart, removeFromCartById, updateCartById } from "../api/cart_product";
import { ICartProduct, IUpdateCartProductDto } from "../api/models/cart_product";
import { CharacteristicTypeEnum } from "../api/models/characteristic";
import { IProduct, IVariantDto } from "../api/models/product";
import { IShipmentProductMeta } from "../api/models/shipment";
import { getProductBySlug } from "../api/product";
import { useAuthStore } from "../store/auth";
import { useCartStore } from "../store/cart";

import { AppError, errorMessagePerKey } from "./error";
import { isDefinedAndNotNull } from "./object";
import { undefinedIfEmpty } from "./string";

const waitForCartItemsToLoadPromise: {
  resolve: null | ((value: void | PromiseLike<void>) => void);
  reject: null | ((reason?: any) => void);
} = { resolve: null, reject: null };

export async function addToRemoteOrLocalCart(
  product: IProduct,
  quantity: number,
  variantId?: string
): Promise<ICartProduct> {
  let cartItem: ICartProduct;
  const cartStore = useCartStore();

  try {
    cartStore.isMenuOpen = true;
    cartStore.isUpdatingItems = true;

    variantId =
      (product.variants ?? []).find(({ id }) => id === variantId)?.id ??
      product.variants?.find(({ quantityInStock }) => quantityInStock >= 1)?.id;

    if (useAuthStore().isUserLoggedIn) {
      cartItem = await addToCart({ productId: product.id, quantity, variantId });
      cartItem.product = product;
    } else {
      const localId = `${product.id}-${variantId}`;

      store.transact(
        "cart",
        (cartItems: ICartProduct[]) => {
          const foundCartItem = cartItems.find((storedCartItem) => storedCartItem.id === localId);
          if (foundCartItem) {
            foundCartItem.quantity++;

            cartItem = foundCartItem;

            return;
          }

          const newCartItem: ICartProduct = {
            id: localId,
            product,
            quantity,
            variantId,
          };
          cartItems.push(newCartItem);

          cartItem = newCartItem;
        },
        []
      );
    }

    const foundCartItemIndex = cartStore.items?.findIndex(({ id }) => cartItem.id === id);
    if (isDefinedAndNotNull(foundCartItemIndex) && foundCartItemIndex !== -1) {
      Object.assign(cartStore.items![foundCartItemIndex], cartItem!);
    } else {
      cartStore.items!.push(cartItem!);
    }

    return cartItem!;
  } finally {
    cartStore.isUpdatingItems = false;
  }
}

export async function removeFromRemoteOrLocalCart(id: string) {
  const cartStore = useCartStore();

  if (cartStore.isIndividualCheckout && cartStore.individualItem?.id === id) {
    cartStore.individualItem = null;

    return;
  }

  try {
    cartStore.isUpdatingItems = true;
    if (useAuthStore().isUserLoggedIn) {
      await removeFromCartById(id);
    } else {
      store.transact("cart", (cartItems: ICartProduct[]) => {
        const foundIndex = cartItems.findIndex((storedCartItem) => storedCartItem.id === id);
        if (foundIndex !== -1) {
          cartItems.splice(foundIndex, 1);
        }
      });
    }

    const index = cartStore.items?.findIndex((item) => item.id === id);
    if (isDefinedAndNotNull(index) && index !== -1) {
      cartStore.items!.splice(index, 1);
    }
  } finally {
    cartStore.isUpdatingItems = false;
  }
}

export async function updateRemoteOrLocalCart(
  id: string,
  productId: string,
  payload: IUpdateCartProductDto,
  { checkForExistingVariant = true }: { checkForExistingVariant?: boolean } = {}
) {
  const cartStore = useCartStore();
  cartStore.isUpdatingItems = true;

  if (cartStore.isIndividualCheckout && cartStore.individualItem?.id === id) {
    Object.assign(cartStore.individualItem, payload);

    // Espera isUpdatingItems se propagar para que o checkout capture a mudança e recalcule o shipment
    await nextTick();
    cartStore.isUpdatingItems = false;

    return;
  }

  try {
    if (checkForExistingVariant) {
      const existingItemVariant = cartStore.items?.find(
        (cartItem) => cartItem.product.id === productId && cartItem.variantId === payload.variantId
      );
      if (existingItemVariant) {
        throw new AppError(errorMessagePerKey.PRODUCT_VARIANT_ALREADY_EXISTS_ON_CART);
      }
    }

    const foundCartItem = cartStore.items?.find((item) => item.id === id);
    if (foundCartItem) {
      if (foundCartItem.quantity < 1) {
        payload.quantity = 1;
      }

      Object.assign(foundCartItem, payload);
    }

    if (useAuthStore().isUserLoggedIn) {
      await updateCartById(id, payload);
    } else {
      store.transact("cart", (storageCartItems: ICartProduct[]) => {
        const foundStorageCartItem = storageCartItems.find(
          (storedCartItem) => storedCartItem.id === id
        );
        if (foundStorageCartItem) {
          Object.assign(foundStorageCartItem, payload);
        }
      });
    }
  } finally {
    cartStore.isUpdatingItems = false;
  }
}

export async function getRemoteOrLocalCart(): Promise<ICartProduct[]> {
  const cartStore = useCartStore();

  try {
    cartStore.isUpdatingItems = true;

    if (useAuthStore().isUserLoggedIn) {
      return getMyCart();
    }

    return store.get("cart", []);
  } finally {
    cartStore.isUpdatingItems = false;
  }
}

export async function fetchAndStoreCart() {
  try {
    const cartStore = useCartStore();

    // Vue Router não está carregado nesse momento, então pega a URL pelo window.location
    const params = new URLSearchParams(window.location.search);
    const individualItem = getIndividualItemFromQuery({
      "individual-item-slug": params.get("individual-item-slug"),
      "individual-item-quantity": params.get("individual-item-quantity"),
      "individual-item-variant": params.get("individual-item-variant"),
    });

    const [cartItems, product] = await Promise.all([
      getRemoteOrLocalCart(),
      individualItem ? getProductBySlug(individualItem.slug) : null,
    ]);

    if (product) {
      cartStore.checkoutIndividualItem(
        product,
        individualItem?.quantity ?? 1,
        individualItem?.variantId
      );
    }

    cartStore.items = cartItems;
    waitForCartItemsToLoadPromise.resolve?.();
  } catch (err) {
    waitForCartItemsToLoadPromise.reject?.();

    throw err;
  }
}

/** Atualmente pode ser chamado apenas 1 vez */
export async function waitForCartItemsLoad() {
  const cartStore = useCartStore();
  if (cartStore.items) {
    return;
  }

  return new Promise<void>((resolve, reject) => {
    waitForCartItemsToLoadPromise.resolve = resolve;
    waitForCartItemsToLoadPromise.reject = reject;
  });
}

export async function syncLocalCartWithRemote() {
  const cartStore = useCartStore();

  if (cartStore.items?.length) {
    await syncOrderItems(cartStore.items);
  }

  store.remove("cart");
  await fetchAndStoreCart();
}

export async function syncOrderItems(
  orderProducts: { product: { id: string }; quantity: number; variantId?: string | null }[]
) {
  // Ignora erros pelo uso de Promise.allSettled ao invés de Promise.all
  await Promise.allSettled(
    orderProducts.map((item) =>
      addToCart({
        productId: item.product.id,
        quantity: item.quantity,
        variantId: item.variantId ?? undefined,
      })
    )
  );

  await fetchAndStoreCart();
}

export function findChosenVariantBySelectedCharacteristics(
  variants: IVariantDto[],
  selectedVariantCharacteristicPerKey: Record<CharacteristicTypeEnum, string | null>
) {
  return variants.find(({ metadata }) => {
    const keys = Object.keys(metadata);
    let matches = 0;

    keys.forEach((key) => {
      if (
        metadata[<CharacteristicTypeEnum>key] ===
        selectedVariantCharacteristicPerKey[<CharacteristicTypeEnum>key]
      ) {
        matches++;
      }
    });

    return matches === keys.length;
  });
}

export function isCartItemMetaProductPair(
  cartItem: ICartProduct,
  metaProduct: IShipmentProductMeta
) {
  return (
    cartItem.product.id === metaProduct.id &&
    undefinedIfEmpty(cartItem.variantId) === undefinedIfEmpty(metaProduct.variantId)
  );
}

export function getIndividualItemFromQuery(query: LocationQuery) {
  return query["individual-item-slug"]
    ? {
        slug: String(query["individual-item-slug"]),
        quantity: Number(query["individual-item-quantity"]) || undefined,
        variantId: query["individual-item-variant"]
          ? String(query["individual-item-variant"])
          : undefined,
      }
    : null;
}

export function getQueryFromIndividualItem(item: {
  slug: string;
  quantity?: number;
  variantId?: string;
}) {
  return {
    "individual-item-slug": item.slug,
    "individual-item-quantity": item.quantity,
    "individual-item-variant": item.variantId,
  };
}
