<template>
  <div class="flex flex-col items-center justify-center px-10">
    <div>
      <Icon
        class="mx-auto mb-9 text-center text-primary"
        name="sentiment_dissatisfied"
        source="material-symbols"
        size="56px"
      />
      <h2 class="text-center">Falha no carregamento</h2>
      <p class="mt-5 mb-10 text-center">Verifique sua conexão com a internet e tente novamente.</p>
      <Button class="mx-auto mt-10 w-fit px-9" @click="reloadPage">Atualizar página</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from "./Button.vue";
import Icon from "./Icon.vue";

function reloadPage() {
  window.location.reload();
}
</script>
