import { defineStore } from "pinia";
import { nextTick } from "vue";

import {
  getCategoryNamePerPartialTree,
  getFirstLevelCategoriesWithChildren,
} from "../api/category";
import { getProfile, updateProfile } from "../api/me";
import { getMetadataKeys } from "../api/metadata";
import { ICard } from "../api/models/card";
import { ICategory } from "../api/models/category";
import { PendingActionCallback, PendingActionEnum } from "../api/models/global";
import { CardBrandEnum } from "../api/models/order";
import { PermissionControllerEnum } from "../api/models/permission";
import { IPagePermission, IUpdateProfileRequest, IUser } from "../api/models/user";
import { IUserAddress } from "../api/models/user_address";
import { useModal } from "../composables/useModal";
import router, { flatRoutes } from "../router";
import { customProductsListPaths, handleAppError } from "../utils";

import { useAuthStore } from "./auth";

interface IMetadata {
  availableCardBrands: CardBrandEnum[];
}

const navbarCategoriesPlaceholder = [
  { id: "1", name: "Sex Shop", partialTreeJoin: "", children: [], level: 1, parent: null },
  {
    id: "2",
    name: "Higiene e Bem-estar",
    partialTreeJoin: "",
    children: [],
    level: 1,
    parent: null,
  },
  { id: "3", name: "Moda Íntima", partialTreeJoin: "", children: [], level: 1, parent: null },
  { id: "4", name: "Linha Noite", partialTreeJoin: "", children: [], level: 1, parent: null },
];

export const useUserStore = defineStore({
  id: "user",
  state: () => {
    return {
      isLoading: false,
      hasNetworkError: false,
      activeUser: null as IUser | null,
      initialLoggedInRoute: "/",
      isMobileMenuOpen: false,
      isLoggingOut: false,
      windowScrollBlockers: new Set<string>(),
      logoutModal: useModal(),
      firstLevelCategoriesWithChildren: navbarCategoriesPlaceholder as ICategory[],
      areNavbarCategoriesLoaded: false,
      areCategoriesTranslationLoaded: false,
      categoryNamePerPartialTree: {} as Record<string, string>,
      wishListItemsCount: null as number | null,
      prevPath: null as string | null,
      metadata: null as IMetadata | null,
      afterLoginAction: null as null | {
        action: PendingActionEnum;
        callback: PendingActionCallback;
        path: string;
      },
    };
  },
  getters: {
    currentCategoryTree() {
      return (<string[]>router.currentRoute.value.params?.categories ?? []).join(",");
    },
    currentCategoryTranslation(state) {
      const currentCategoryTree = <string>this.currentCategoryTree;

      return state.categoryNamePerPartialTree[currentCategoryTree];
    },
  },
  actions: {
    setActiveUser(user: IUser | null) {
      this.activeUser = user;
      if (this.activeUser) {
        this.configCardsForUser();
        this.configAddressesForUser();
      }
    },
    configCardsForUser(newCards?: ICard[]) {
      newCards ??= this.activeUser!.cards ?? [];

      this.activeUser!.mainCard = newCards!.find(({ isMainCard }) => isMainCard) ?? newCards![0];
      this.activeUser!.cards = newCards!;
      this.activeUser!.cards.sort((left) => (left.isMainCard ? -1 : 1));
    },
    configAddressesForUser(newAddresses?: IUserAddress[]) {
      newAddresses ??= this.activeUser!.addresses ?? [];

      this.activeUser!.mainAddress =
        newAddresses!.find(({ isMainAddress }) => isMainAddress) ?? newAddresses![0];
      this.activeUser!.addresses = newAddresses!;
      this.activeUser!.addresses.sort((left) => (left.isMainAddress ? -1 : 1));
    },
    setNewMainCardForUser(newMainCard: ICard) {
      this.activeUser!.mainCard = newMainCard;
      this.activeUser!.cards.forEach((card) => {
        card.isMainCard = card.id === newMainCard.id;
      });
    },
    setNewMainAddressForUser(newMainAddress: IUserAddress) {
      this.activeUser!.mainAddress = newMainAddress;
      this.activeUser!.addresses.forEach((address) => {
        address.isMainAddress = address.id === newMainAddress.id;
      });
    },
    async fetchRequiredDataOnStoreStart() {
      const [categories, categoryNamePerPartialTree, metadata] = await Promise.allSettled([
        getFirstLevelCategoriesWithChildren(),
        getCategoryNamePerPartialTree(),
        getMetadataKeys(["availableCardBrands"]),
      ]);

      if (metadata.status === "fulfilled") {
        this.metadata = <any>metadata.value;
      } else {
        handleAppError(metadata.reason);
      }

      if (categories.status === "fulfilled") {
        this.firstLevelCategoriesWithChildren = categories.value;
        this.areNavbarCategoriesLoaded = true;
      } else {
        handleAppError(categories.reason);
      }

      if (categoryNamePerPartialTree.status === "fulfilled") {
        this.categoryNamePerPartialTree = categoryNamePerPartialTree.value;
        this.areCategoriesTranslationLoaded = true;
      } else {
        handleAppError(categoryNamePerPartialTree.reason);
      }

      customProductsListPaths.forEach(({ tree, name }) => {
        this.categoryNamePerPartialTree[tree] = name;
      });
    },

    doesActiveUserHavePermissionController(permissionController: PermissionControllerEnum) {
      return this.activeUser?.permissions
        ?.map(({ controller }) => controller)
        .includes(permissionController);
    },

    loadInitialLoggedInRoute() {
      // Seleciona a rota de maior prioridade (menor número) que o usuário tem permissão.
      let priority = 1000;

      flatRoutes.forEach(({ path, meta }) => {
        const hasPermissions = !meta?.permissions || this.checkPermissions(meta.permissions ?? []);
        if (
          (meta?.priority || meta?.priority === 0) &&
          hasPermissions &&
          meta.priority < priority
        ) {
          priority = meta.priority;
          this.initialLoggedInRoute = path;
        }
      });
    },

    checkPermissions(requiredPermissions: IPagePermission[]) {
      return !requiredPermissions.some((requiredPermission) => {
        const hasPermission = (this.activeUser?.permissions ?? []).some(
          (permission) =>
            permission.module === requiredPermission.module &&
            (requiredPermission.controller
              ? permission.controller === requiredPermission.controller
              : true) &&
            (requiredPermission.access ? permission.access === requiredPermission.access : true)
        );

        return !hasPermission;
      });
    },

    async loadUserData() {
      if (!this.activeUser) {
        await this.reloadUserData();
      }
    },

    async reloadUserData() {
      this.setActiveUser(null); //força limpeza dos dados do usuário
      if (useAuthStore().accessToken) {
        try {
          this.isLoading = !this.activeUser;

          this.setActiveUser(await getProfile());
          this.loadInitialLoggedInRoute();
        } catch (_) {
          this.hasNetworkError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },

    async updateProfile(data: IUpdateProfileRequest) {
      const updatedProfile = await updateProfile(data);

      const userStore = useUserStore();
      userStore.setActiveUser({ ...userStore.activeUser, ...updatedProfile });
    },

    async logout() {
      this.isLoggingOut = true;
      await useAuthStore().logout();
      this.isLoggingOut = false;
      this.logoutModal.closeModal();
      router.push("/");
    },

    /** Executa a ação (callback) imediatamente caso o usuário esteja logado, se não, armazena a ação para ser executada posteriormente com o `runPendingAction` */
    async ensureIsLoggedInForAction(
      action: PendingActionEnum,
      callback: PendingActionCallback,
      {
        shouldScroll,
        overlayQuery,
      }: { shouldScroll?: boolean; overlayQuery?: Record<string, string> } = {}
    ) {
      const currentScroll = $(document).scrollTop() ?? 0;
      if (useAuthStore().isUserLoggedIn) {
        await callback(false, currentScroll);
      } else {
        let pathName = router.currentRoute.value.fullPath;
        if (Object.keys(overlayQuery ?? {}).length) {
          const url = new URL(window.location.href);

          Object.entries(overlayQuery!).forEach(([key, value]) => {
            url.searchParams.set(key, value);
          });

          pathName = `${url.pathname}${url.search}`;
        }

        this.afterLoginAction = {
          action,
          callback: async () => {
            if (shouldScroll) {
              await nextTick();
              $(document).scrollTop(currentScroll);
            }

            return callback(true, currentScroll);
          },
          path: pathName,
        };

        router.push({ path: "/login", query: { "redirect-to": this.afterLoginAction.path } });
      }
    },

    async runPendingAction<T>(): Promise<{ result: T; action: PendingActionEnum } | null> {
      if (
        this.prevPath === "/login" &&
        useAuthStore().isUserLoggedIn &&
        router.currentRoute.value.fullPath === this.afterLoginAction?.path
      ) {
        // Parâmetros pro callback foram inseridos no momento de sua criação com um sub callback
        const result = await (<any>this.afterLoginAction).callback();
        const data = { action: this.afterLoginAction.action, result };

        this.afterLoginAction = null;

        return data;
      }

      this.afterLoginAction = null;

      return null;
    },
  },
});
