import { UserRoleEnum } from "./user";

export enum PermissionModuleEnum {
  DASH = "DASH",
  WEB = "WEB",
  APP = "APP",
}

export enum PermissionControllerEnum {
  USERS = "USERS",
  METRICS = "METRICS",
}

export const PermissionTranslated = {
  [PermissionModuleEnum.DASH]: "Dashboard",
};

export const PermissionControllerTranslated: Record<PermissionControllerEnum, string> = {
  [PermissionControllerEnum.USERS]: "Usuários",
  [PermissionControllerEnum.METRICS]: "Métricas",
};

export enum PermissionAccessEnum {
  READ = "READ",
  WRITE = "WRITE",
}

export const PermissionAccessTranslated: Record<PermissionAccessEnum, string> = {
  [PermissionAccessEnum.WRITE]: "Editar",
  [PermissionAccessEnum.READ]: "Visualizar",
};

export interface IPermission {
  id: string;
  role: UserRoleEnum;
  module: PermissionModuleEnum;
  controller: PermissionControllerEnum;
  access: PermissionAccessEnum;
}
