import { LocationQueryValue } from "vue-router";

import router from "../router";

const DISPOSABLES_QUERY_PARAMS_KEYS = ["google-oauth-local-token", "error"];

export function getSingleQueryValue(value: LocationQueryValue | LocationQueryValue[]) {
  return (Array.isArray(value) ? value[0] : value) || undefined;
}

export async function removeDisposablesQueryParams() {
  const route = router.currentRoute.value;
  let hasDisposablesQueryParams = false;
  const query = { ...route.query };
  if (query) {
    Object.keys(query).forEach((key: string) => {
      if (DISPOSABLES_QUERY_PARAMS_KEYS.includes(key)) {
        hasDisposablesQueryParams = true;
        delete query[key];
      }
    });
  }

  if (hasDisposablesQueryParams) {
    router.replace({ path: route.path, query });
  }
}
