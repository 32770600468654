export const translationPerProperty = {
  name: "Nome",
  email: "E-mail",
  password: "Senha",
  cpf: "CPF",
  cpfOrCnpj: "CPF",
  birthdate: "Data de nascimento",
  phoneNumber: "Telefone",
  gender: "Gênero",
  addressName: "Título",
  cardCVV: "CVV",
};
