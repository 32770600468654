import { defineStore } from "pinia";

import { IProduct } from "../api/models/product";

export const useProductStore = defineStore({
  id: "product",
  state: () => {
    return {
      product: null as IProduct | null,
    };
  },
});
