import { ProductListingFilterEnum, ProductListingSortEnum } from "../api/models/product";

export const googleOAuthBaseURL = `${<string>import.meta.env.VITE_API_BASE_URL}/auth/google`;

export const sexshopBrandName = <string>import.meta.env.VITE_SEXSHOP_BRAND_NAME;
export const lastUnitsQuantity = Number(import.meta.env.VITE_LAST_UNITS_QUANTITY);
export const MIN_ORDER_VALUE = Number(import.meta.env.VITE_MIN_ORDER_VALUE);
export const MAX_INSTALLMENTS = Number(import.meta.env.VITE_MAX_INSTALLMENTS);
export const VITE_COUPON = import.meta.env.VITE_COUPON;
export const COUPON_PERCENTAGE = Number(import.meta.env.VITE_COUPON_PERCENTAGE);
export const DEFAULT_META_DESCRIPTION = `${sexshopBrandName}: Autoempoderamento e glamour com milhares de produtos eróticos e lingeries. Alta qualidade, suporte excepcional e experiências únicas para você.`;

export const generalInfo = {
  contactEmail: <string>import.meta.env.VITE_CONTACT_EMAIL,
  contactNumber: <string>import.meta.env.VITE_CONTACT_NUMBER,
  workTime: <string>import.meta.env.VITE_WORK_TIME,
  callbackPeriod: <string>import.meta.env.VITE_CALLBACK_PERIOD,
  instagram: <string>import.meta.env.VITE_INSTAGRAM,
  facebook: <string>import.meta.env.VITE_FACEBOOK,
  tiktok: <string>import.meta.env.VITE_TIKTOK,
};

generalInfo.instagram = generalInfo.instagram === "none" ? "" : generalInfo.instagram;
generalInfo.facebook = generalInfo.facebook === "none" ? "" : generalInfo.facebook;
generalInfo.tiktok = generalInfo.tiktok === "none" ? "" : generalInfo.tiktok;

export const BrazilianStates = [
  { value: "AC", name: "Acre" },
  { value: "AL", name: "Alagoas" },
  { value: "AM", name: "Amazonas" },
  { value: "AP", name: "Amapá" },
  { value: "BA", name: "Bahia" },
  { value: "CE", name: "Ceará" },
  { value: "DF", name: "Distrito Federal" },
  { value: "ES", name: "Espírito Santo" },
  { value: "GO", name: "Goiás" },
  { value: "MA", name: "Maranhão" },
  { value: "MG", name: "Minas Gerais" },
  { value: "MS", name: "Mato Grosso do Sul" },
  { value: "MT", name: "Mato Grosso" },
  { value: "PA", name: "Pará" },
  { value: "PB", name: "Paraíba" },
  { value: "PE", name: "Pernambuco" },
  { value: "PI", name: "Piauí" },
  { value: "PR", name: "Paraná" },
  { value: "RJ", name: "Rio de Janeiro" },
  { value: "RN", name: "Rio Grande do Norte" },
  { value: "RO", name: "Rondônia" },
  { value: "RR", name: "Roraima" },
  { value: "RS", name: "Rio Grande do Sul" },
  { value: "SC", name: "Santa Catarina" },
  { value: "SE", name: "Sergipe" },
  { value: "SP", name: "São Paulo" },
  { value: "TO", name: "Tocantins" },
];

export const customProductsListPaths: {
  name: string;
  tree: string;
  filter: ProductListingFilterEnum;
  sort: ProductListingSortEnum;
  shouldShowInNavbar: boolean;
}[] = [
  {
    name: "Novidades",
    tree: "novidades",
    filter: ProductListingFilterEnum.NEW,
    sort: ProductListingSortEnum.NEWEST,
    shouldShowInNavbar: true,
  },
  {
    name: "Mais vendidos",
    tree: "mais-vendidos",
    filter: ProductListingFilterEnum.ALL,
    sort: ProductListingSortEnum.TOP_SELLERS,
    shouldShowInNavbar: true,
  },
  {
    name: "Ofertas",
    tree: "ofertas",
    filter: ProductListingFilterEnum.OFFERS,
    sort: ProductListingSortEnum.TOP_SELLERS,
    shouldShowInNavbar: false,
  },
  {
    name: "Todos os produtos",
    tree: "todos",
    filter: ProductListingFilterEnum.ALL,
    sort: ProductListingSortEnum.TOP_SELLERS,
    shouldShowInNavbar: false,
  },
  {
    name: "Busca",
    tree: "busca",
    filter: ProductListingFilterEnum.ALL,
    sort: ProductListingSortEnum.DEFAULT,
    shouldShowInNavbar: false,
  },
];
