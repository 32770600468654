import { h } from "vue";
import { PluginOptions, POSITION, useToast } from "vue-toastification";

import Icon from "../components/Icon.vue";

import { IIcon } from "./helpers";

export function toast(type: "error" | "success" | "info", text: string, seconds = 5) {
  const spawnToast = useToast();

  const toastBaseOptions: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: seconds * 1000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    hideProgressBar: false,
    closeButton: false,
  };

  const toastContent = h("div", { innerHTML: text });

  switch (type) {
    case "error": {
      spawnToast(toastContent, {
        ...toastBaseOptions,
        icon: h(Icon as any, <IIcon>{
          name: "exclamation-circle",
          size: "30px",
          source: "line-awesome",
        }),
        toastClassName: "bg-error w-80 rounded-sm",
        bodyClassName: "text-white whitespace-pre-wrap font-secondary",
      });

      break;
    }

    case "success": {
      spawnToast(toastContent, {
        ...toastBaseOptions,
        icon: h(Icon as any, <IIcon>{ name: "check", size: "24px", source: "line-awesome" }),
        toastClassName: "bg-success w-80 rounded-sm",
        bodyClassName: "text-white",
      });

      break;
    }

    default: {
      spawnToast(toastContent, {
        ...toastBaseOptions,
        icon: h(Icon, <IIcon>{ name: "info-circle", size: "28px", source: "line-awesome" }),
        toastClassName: "bg-info w-80 rounded-sm text-body",
        bodyClassName: "text-body",
      });

      break;
    }
  }
}
