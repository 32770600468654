import { ref, readonly } from "vue";

const isDarkTheme = ref(false);

export function useTheme() {
  function toggleTheme() {
    if (isDarkTheme.value) {
      document.getElementById("app")?.classList.remove("dark");
      localStorage.removeItem("theme");
    } else {
      document.getElementById("app")?.classList.add("dark");
      localStorage.setItem("theme", "dark");
    }

    isDarkTheme.value = !isDarkTheme.value;
  }

  function loadTheme() {
    if (localStorage.getItem("theme") === "dark") {
      document.getElementById("app")?.classList.add("dark");

      isDarkTheme.value = true;
    }
  }

  return {
    isDarkTheme: readonly(isDarkTheme),
    toggleTheme,
    loadTheme,
  };
}
