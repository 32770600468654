import { apiGet } from "./_server";
import {
  IGetProductsListDto,
  IProduct,
  IProductPaginationMetadataResponseDto,
  IProductPaginationResponseDto,
} from "./models/product";

export async function getProducts(
  query: IGetProductsListDto
): Promise<IProductPaginationResponseDto> {
  return apiGet({ path: "app/product", config: { params: { json: JSON.stringify(query) } } });
}

export async function getProductsMetadata(
  query: IGetProductsListDto
): Promise<IProductPaginationMetadataResponseDto> {
  return apiGet({
    path: "app/product/metadata",
    config: { params: { json: JSON.stringify(query) } },
  });
}

export async function getProductBySlug(slug: string): Promise<IProduct> {
  return apiGet({ path: `app/product/${slug}` });
}
