import { apiDelete, apiGet, apiPatch, apiPost } from "./_server";
import { IAddCartProductDto, ICartProduct, IUpdateCartProductDto } from "./models/cart_product";

export async function getMyCart() {
  return apiGet<ICartProduct[]>({ path: "cart_product" });
}

export async function addToCart(payload: IAddCartProductDto) {
  return apiPost<ICartProduct>({ path: "cart_product", data: payload });
}

export async function updateCartById(id: string, payload: IUpdateCartProductDto) {
  return apiPatch<ICartProduct>({ path: `cart_product/${id}`, data: payload });
}

export async function removeFromCartById(id: string) {
  return apiDelete<void>({ path: `cart_product/${id}` });
}

export async function clearMyCart() {
  return apiPost<void>({ path: "cart_product/clear" });
}
