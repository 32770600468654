<template>
  <div :class="['loading rounded-tiny', classes]"></div>
</template>

<script setup lang="ts">
import { computed } from "@vue/runtime-core";

export type SkeletonKind =
  | "card"
  | "title"
  | "subtitle"
  | "paragraph"
  | "button"
  | "input"
  | "inputWithoutLabel"
  | "header"
  | "image"
  | "avatar";

const props = withDefaults(
  defineProps<{
    kind?: SkeletonKind;
  }>(),
  {
    kind: undefined,
  }
);

const classes = computed(() => {
  switch (props.kind) {
    case "card":
      return "h-40 w-40";
    case "title":
      return "h-10 w-full";
    case "subtitle":
      return "h-8 w-full";
    case "paragraph":
      return "h-5 w-full";
    case "button":
      return "min-h-button w-full max-w-button rounded";
    case "input":
      return "h-20 w-full";
    case "inputWithoutLabel":
      return "h-11 w-full";
    case "header":
      return "h-24 w-full";
    case "image":
      return "h-40 w-full";
    case "avatar":
      return "h-20 w-20";
    default:
      return null;
  }
});
</script>

<style lang="postcss" scoped>
.loading {
  background-color: var(--skeleton-background);
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 30%,
      var(--skeleton-shine) 50%,
      rgba(255, 255, 255, 0) 70%
    )
    var(--skeleton-background);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s shine ease-in-out infinite;
}

@keyframes shine {
  to {
    background-position-x: -20%;
  }
}
</style>
