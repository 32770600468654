import { IBaseEntity } from "./base_entity";
import { IBrand } from "./brand";
import { ICategory } from "./category";
import { CharacteristicTypeEnum, CharacteristicsPerType } from "./characteristic";
import { IPaginationDto, IPaginationMetadataResponseDto, IPaginationResponseDto } from "./global";
import { IReview } from "./review/review";

export enum SexshopEnum {
  MIESS = "MIESS", // Miess
  DSS = "DSS", // Distribuidora Sex Shop
  LDD = "LDD", // Loja do Desejo
}

export enum ProductNameExtensionEnum {
  VUNA = "Vuna",
  KORES = "Kores",
  TRANI = "Trani",
  WEXINOS = "Wexinos",
  YEPTA = "Yepta",
  LUMO = "Lumo",
  TRISKON = "Triskon",
  QETRA = "Qetra",
  VEKTON = "Vekton",
  DRAFOS = "Drafos",
}

export enum ProductListingFilterEnum {
  ALL = "ALL",
  NEW = "NEW",
  OFFERS = "OFFERS",
}

export enum ProductListingSortEnum {
  DEFAULT = "DEFAULT",
  NEWEST = "NEWEST",
  TOP_SELLERS = "TOP_SELLERS",
  HIGHEST_PRICE = "HIGHEST_PRICE",
  LOWEST_PRICE = "LOWEST_PRICE",
}

export interface IProduct extends IBaseEntity {
  isInRequestingUserWishList: boolean;
  requestingUserDeliveryDate: Date | null;
  requestingUserPurchaseDate: Date | null;
  requestingUserReview: IReview | null;
  variantsCharacteristicsPerKey: Record<CharacteristicTypeEnum, string[]>;
  variantsQuantityPerCombination: Record<string, number>;
  deepestCategory: ICategory;
  reviews: IReview[];
  brand: IBrand;
  equivalenceId: string | null;
  isActive: boolean;
  rawId: string;
  rawSku: string;
  originalWebpageUrl: string;
  sexshop: SexshopEnum;
  shortDescription: string | null;
  fullDescription: string | null;
  enhancedDescription: string | null;
  instructionsForUse: string | null;
  measurements: string | null;
  slug: string;
  extension: ProductNameExtensionEnum | null;
  gtin: string | null;
  ean: string | null;
  name: string;
  videoUrl: string | null;
  currency: string;
  basePrice: number | null;
  profitMargin: number | null;
  price: number | null;
  offerPrice: number | null;
  discount: number | null;
  offerValidUntil: Date | null;
  quantityInStock: number;
  averageRating: number | null;
  ratings: number;
  ratingDistribution: Record<number, number> | null;
  isDatasheetVisible: boolean;
  defaultVariantId: string | null;
  variants: IVariantDto[] | null;
  images: IImageDto[];
  datasheet: Record<string, string> | null;
}

export interface IGetProductsListDto extends IPaginationDto {
  search?: string;
  categoryTrees?: string[];
  minPrice?: number;
  maxPrice?: number;
  characteristics?: string[];
  brandSlugs?: string[];
  filter?: ProductListingFilterEnum;
  sort?: ProductListingSortEnum;
  excludeListingMetadata?: boolean;
}

export interface IProductPaginationResponseDto {
  data: IProduct[];
  searchMetadata: IProductPaginationMetadataResponseDto;
}

export interface IProductPaginationMetadataResponseDto extends IPaginationMetadataResponseDto {
  price?: IPriceDto | null;
  childrenCategories?: ICategory[];
  characteristicsPerType?: CharacteristicsPerType;
  brands?: IBrand[];
}

export interface IPriceDto {
  min: number;
  max: number;
}

export interface IVariantDto {
  id: string;
  altId?: string;
  sku?: string;
  images: IImageDto[];
  quantityInStock: number;
  metadata: Partial<Record<CharacteristicTypeEnum, string>>;
}

export interface IImageDto {
  url: string;
  alt: string;
}
