// Request
import { makeOptionsFromEnum } from "../../utils";

import { ICard } from "./card";
import {
  IPermission,
  PermissionAccessEnum,
  PermissionControllerEnum,
  PermissionModuleEnum,
} from "./permission";
import { IUserAddress } from "./user_address";

export interface ICreateRequest {
  role: UserRoleEnum;
  name: string;
  email: string;
  password?: string;
}

export interface IUpdateRequest {
  name?: string;
  email?: string;
  permissions?: PermissionEnum[] | null;
  role?: UserRoleEnum;
}

export interface IUpdateProfileRequest {
  name?: string;
  phoneNumber?: string;
  birthdate?: string;
  isReceivingTransactionalEmails?: boolean;
  cpfOrCnpj?: string;
  gender?: string;
}

export interface IUpdatePasswordRequest {
  currentPassword: string;
  password: string;
}

// Response
export enum UserRoleEnum {
  USER = "USER",
  ADMINISTRATOR = "ADMINISTRATOR",
}

export const UserRoleTranslated: Record<UserRoleEnum, string> = {
  [UserRoleEnum.USER]: "Usuário",
  [UserRoleEnum.ADMINISTRATOR]: "Master",
};

export const userRoleOptions: { label: string; value: string }[] = Object.values(UserRoleEnum).map(
  (userRole) => ({ label: UserRoleTranslated[userRole], value: userRole })
);

const adminUserRoles = [UserRoleEnum.ADMINISTRATOR];

export const adminUserRoleOptions = userRoleOptions.filter((userRole) =>
  adminUserRoles.includes(userRole.value as UserRoleEnum)
);

export enum PermissionEnum {
  USERS = "USERS",
  DASH = "DASH",
}

export interface IPagePermission {
  module: PermissionModuleEnum;
  controller?: PermissionControllerEnum;
  access?: PermissionAccessEnum;
}

export enum GenderEnum {
  DEFAULT = "DEFAULT",
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface IIPermission {
  access: PermissionAccessEnum;
  controller: PermissionControllerEnum;
  id: string;
  module: PermissionModuleEnum;
  role: UserRoleEnum;
}

export interface IUser {
  id: string;
  role: UserRoleEnum;
  roleTranslated: string;
  permissions: IPermission[] | null;
  mainAddress?: IUserAddress;
  mainCard?: ICard;
  addresses: IUserAddress[];
  cards: ICard[];
  permissionsTranslated: string[] | null;
  name: string;
  email: string;
  cpfOrCnpj: string;
  phoneNumber: string;
  birthdate: string;
  gender: GenderEnum;
  profilePictureUrl?: string | null;
  isReceivingTransactionalEmails: boolean;
  isOauthSignInAccount: boolean;
}

export const genderTranslation: Record<GenderEnum, string> = {
  DEFAULT: "Prefiro não informar",
  MALE: "Masculino",
  FEMALE: "Feminino",
  OTHER: "Outro",
};

export const genderOptions = makeOptionsFromEnum(GenderEnum, genderTranslation);
