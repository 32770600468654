import { AxiosRequestConfig } from "axios";

import { apiPost } from "./_server";
import {
  IAuthenticateWithGoogleDto,
  ILoginRequest,
  IResetPasswordRequest,
  ISession,
  ISignUpRequest,
} from "./models/auth";

export async function signUp(payload: ISignUpRequest): Promise<ISession> {
  return apiPost({ path: "auth/signup", data: payload });
}

export async function login(payload: ILoginRequest): Promise<ISession> {
  return apiPost({ path: "auth/login", data: payload });
}

export async function logout(config?: AxiosRequestConfig): Promise<void> {
  return apiPost({ path: "auth/logout", config });
}

export async function requestPasswordReset(email: string, redirectTo?: string): Promise<void> {
  return apiPost({ path: "auth/request_password_reset", data: { email, redirectTo } });
}

export async function validatePasswordResetCode(passwordResetCode: string): Promise<void> {
  return apiPost({ path: "auth/validate_password_reset_code", data: { passwordResetCode } });
}

export async function resetPassword(payload: IResetPasswordRequest): Promise<ISession> {
  return apiPost({ path: "auth/reset_password", data: payload });
}

export async function authenticateWithGoogle(
  payload: IAuthenticateWithGoogleDto
): Promise<ISession> {
  return apiPost({ path: "auth/google/authenticate", data: payload });
}
