import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
    meta: { title: "Login" },
  },
  {
    path: "/redefinir-senha",
    component: () => import("../views/ResetPassword.vue"),
    meta: { requiresUnauth: true, title: "Redefinir senha com código" },
  },
  {
    path: "/redefinicao-de-senha",
    component: () => import("../views/RequestPasswordReset.vue"),
    meta: { title: "Redefinir senha" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/admin/Error404.vue"),
    meta: { title: "Não encontrada" },
  },
  {
    path: "/404",
    name: "not-found",
    component: () => import("../views/admin/Error404.vue"),
    meta: { title: "Não encontrada" },
  },
  {
    path: "/",
    component: () => import("../components/navbar/admin/LayoutNavbarVertical.vue"),
    children: [
      {
        path: "/clientes/:id?",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/customer/CustomerForm.vue"),
          table: () => import("../views/admin/customer/CustomerTable.vue"),
        },
        meta: {
          requiresAuth: true,
          permissions: [],
          title: "Clientes",
          // permissionController: PermissionController.USERS,
          priority: 3,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../components/navbar/admin/LayoutNavbarVertical.vue"),
    props: {
      tabItems: [
        { name: "Dados pessoais", path: "/minha-conta" },
        { name: "Usuários do painel", path: "/usuarios" },
      ],
    },
    children: [
      {
        path: "/minha-conta",
        component: () => import("../views/admin/settings/MyAccount.vue"),
        meta: { requiresAuth: true, priority: 4, title: "Minha conta" },
      },
      {
        path: "/usuarios/:id?",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/settings/users/UserForm.vue"),
          table: () => import("../views/admin/settings/users/UserTable.vue"),
        },
        meta: {
          requiresAuth: true,
          permissions: [],
          title: "Usuários",
          // permissionController: PermissionController.USERS,
          priority: 4,
        },
      },
    ],
  },
];

export default routes;
