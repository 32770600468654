<template>
  <div ref="wrapperElement" class="relative">
    <template v-if="!hasZoom">
      <TransitionGroup name="fade">
        <div v-show="isImageLoaded || isError" key="img-wrapper" class="h-full w-full">
          <img
            :data-original-src="src"
            :class="['h-full w-full object-center', imageClasses]"
            :src="finalSrc"
            :alt="alt"
            @load="onLoad"
            @error="onError"
          />
          <slot />
        </div>
        <LoadingSkeleton
          v-show="!isImageLoaded && !isError"
          key="skeleton"
          class="absolute left-0 top-0 h-full w-full"
        />
      </TransitionGroup>
    </template>
    <template v-else>
      <VuePhotoZoomPro
        :class="['!block h-full w-full', zoomProClasses]"
        :high-url="finalSrc"
        :width="200"
      >
        <TransitionGroup name="fade">
          <div v-show="isImageLoaded || isError" key="img-wrapper" class="h-full w-full">
            <img
              :data-original-src="src"
              :class="['h-full w-full object-center', imageClasses]"
              :src="finalSrc"
              :alt="alt"
              @load="onLoad"
              @error="onError"
            />
            <slot />
          </div>
          <LoadingSkeleton
            v-show="!isImageLoaded && !isError"
            key="skeleton"
            class="absolute left-0 top-0 h-full w-full"
          />
        </TransitionGroup>
      </VuePhotoZoomPro>
    </template>
  </div>
</template>

<script setup lang="ts">
import isUrl from "is-url";
import { computed, nextTick, ref } from "vue";
import VuePhotoZoomPro from "vue-photo-zoom-pro";

import { getAsset } from "../utils";

import LoadingSkeleton from "./LoadingSkeleton.vue";

const props = withDefaults(
  defineProps<{
    src: string; //
    alt?: string;
    hasZoom?: boolean;
    imageClasses?: string;
    zoomProClasses?: string;
  }>(),
  {
    src: "/fallback.svg",
    alt: undefined,
    imageClasses: undefined,
    zoomProClasses: undefined,
  }
);

const wrapperElement = ref<HTMLElement | null>(null);
const isImageLoaded = ref(false);
const isError = ref(false);

const finalSrc = computed(() => {
  if (isError.value) {
    return getAsset("/fallback.svg");
  }

  return isUrl(props.src) || props.src.startsWith("data:") ? props.src : getAsset(props.src);
});

async function onLoad() {
  if (isError.value) {
    return;
  }

  isImageLoaded.value = true;

  await nextTick();
  emit("onload", wrapperElement.value!, finalSrc.value);
}

async function onError() {
  if (isError.value) {
    return;
  }

  isError.value = true;

  await nextTick();
  emit("onerror", wrapperElement.value!, finalSrc.value);
}

const emit = defineEmits<{
  (event: "onload", wrapper: HTMLElement, url: string): void;
  (event: "onerror", wrapper: HTMLElement, url: string): void;
}>();
</script>

<style scoped lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
